<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('VirtualMuseum.configuration.virtual_krishibid')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <!-- =================Accrodion Start=================== -->
                  <!-- <template> -->
                    <div class="accordion" role="tablist" style="min-width: 100%;">
                        <template v-for="(sinItem, index) in items">
                      <b-card :key="index+1" no-body class="mb-1" >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle="'accordion-productdetails-'+index" visible variant="info" style="display: block;font-size: 20px;font-weight: bold;" class="d-flex">{{ $n(index+1, { useGrouping: false }) }}. {{ currentLocale == 'en' ? sinItem.question : sinItem.question_bn }}</b-button>
                        </b-card-header>
                        <b-collapse style="background-color: #eaeaea; border-radius: 5px 5px 5px 5px; margin-top: 4px;"
                        :id="'accordion-productdetails-'+index"
                        :accordion="'productdetails-accordion-'+index"
                         role="tabpanel">
                          <b-card-body>
                            <b-card-text style="font-size: 15px;">{{ currentLocale == 'en' ? sinItem.answer : sinItem.answer_bn }}</b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      </template>
                    </div>
                  <!-- </template> -->
                  <!-- =================Accrodion End===================== -->
                </b-row>
            </template>
        </iq-card>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import Form from './Form'
// import Details from './Details'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { krishibidQaListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        // Form, Details
    },
    data () {
        return {
            search: {
                invoice_no: '',
                fiscal_year_id: 0,
                month_id: 0,
                allocation_type_id: 0,
                sell_date: ''
                // fertilizer_id: 0
            },
            items: [],
            text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `
        }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    async created () {
        this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            RestApi.getData(virtualMuseumServiceBaseUrl, krishibidQaListApi, params).then(response => {
                if (response.success) {
                    this.items = this.getRelationalData(response.data.data)
                }
            })
        },
        getRelationalData (data) {
           const infoTypeList = this.$store.state.VirtualMuseum.commonObj.informationTypeList.filter(item => item.status === 1)
          const listData = data.map(item => {
            const infoTypeObj = infoTypeList.find(data => data.value === item.info_type_id)
            const infoTypeData = {}
            if (infoTypeObj !== undefined) {
              infoTypeData.information_type_en = infoTypeObj.text_en
              infoTypeData.information_type_bn = infoTypeObj.text_bn
            }
            return Object.assign({}, item, infoTypeData)
          })
          return listData
        }
    }
}
</script>

<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
  .card-header {
    border-bottom: none !important;
}
</style>
